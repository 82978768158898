function offsetFromCursor(items, cursor, readField) {
  for (let i = items.length - 1; i >= 0; --i) {
    const item = items[i];
    if (readField('cursor', item) === cursor) {
      return i + 1;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}

function connectionMerge(existing, incoming, {
  args,
  readField
}) {
  if (!args.after && existing) {
    return existing;
  }
  let cursor = args.after;
  const mergedEdges = existing && existing.edges ? existing.edges.slice(0) : [];
  let offset = offsetFromCursor(mergedEdges, cursor, readField);
  if (offset < 0) offset = mergedEdges.length;
  for (let i = 0; i < incoming.edges.length; i++) {
    mergedEdges[offset + i] = incoming.edges[i];
  }
  return {
    ...incoming,
    edges: mergedEdges
  };
}

export const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        fulfillments: {
          keyArgs: ['query'],
          merge: connectionMerge
        },
        listings: {
          keyArgs: false,
          merge: connectionMerge
        },
        orders: {
          keyArgs: ['query'],
          merge: connectionMerge
        }
      }
    },
    Customer: {
      keyFields: ['email', 'firstName', 'lastName']
    },
    Design: {
      fields: {
        productionFiles: {
          merge: false

        }
      }
    },
    ProductionSpec: {
      keyFields: ['sku']
    }
  }
};
