import { useAuth0 } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@/adapters/oms-client';

export function LoginScreen() {
  const { loginWithRedirect } = useAuth0();
  return <div className="my-20 text-center">
    <div>Logged Out.</div>
    <button className="btn-link" onClick={() => loginWithRedirect({ scope: 'read:orders' })}>Log In</button>
  </div>;
}

export function Auth0ApolloProvider({ children, sellerId = null, initialApolloState }) {
  const apolloClient = useApollo(initialApolloState, sellerId);
  const { isAuthenticated, isLoading } = useAuth0();
  if (isLoading) return <div className="text-center my-20">Loading...</div>;
  if (!isAuthenticated) {
    return <LoginScreen/>;
  }
  if (!apolloClient) {
    return <div className="text-center my-20">Authenticating...</div>;
  }
  return <ApolloProvider client={apolloClient}>
    {children}
  </ApolloProvider>;
}

export function LogoutButton({ className }) {
  const { logout } = useAuth0();
  return (
    <div className={className} onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </div>
  );
}
