import React, { useState, useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import moment from 'moment';
const alertsVar = makeVar([]);

export function sendAlert(data) {
  alertsVar([...alertsVar(), {
    ...data,
    createdAt: Date.now()
  }]);
}

setInterval(()=>{
  let alerts = alertsVar();
  let activeAlerts = [];
  for (let a of alerts) {
    if (a.alertType === 'error') {
      activeAlerts.push(a);
    } else if (moment(a.createdAt).isAfter(moment().subtract(10, 'seconds'))) {
      activeAlerts.push(a);
    }
  }
  alertsVar(activeAlerts);
}, 2000);

export function ShowError(props) {
  const { error } = props;
  useEffect(()=>{
    let message = <div>{props.message}<div className="text-xs py-2">{JSON.stringify(error)}</div></div>;
    sendAlert({ ...props, message, alertType: 'error' });
  }, [props, error]);
  return null;
}

export function ShowAlert(props) {
  useEffect(()=>{
    sendAlert({ ...props });
  }, []);
  return null;
}

function Alert(props) {
  const { message, alertType } = props;
  const [visible, setVisible] = useState(true);
  const handleClose = ()=>{
    setVisible(false);
  };
  let className = 'bg-gray-600';
  if (visible) {
    if (alertType === 'error') {
      className += ' bg-yellow-500';
    }
    return <div className={'bg-opacity-90 text-white p-2 my-2 rounded-lg flex ' + className}>
      <div className="w-60">{message}</div>
      <div className="flex-1 cursor-pointer pl-2" onClick={handleClose}>X</div>
    </div>;
  }
  return null;
}
export function Alerts() {
  const alerts = useReactiveVar(alertsVar);
  return <div className="fixed bottom-2 left-2">
    {alerts.map((a, index)=><Alert key={index} message={a.message} alertType={a.alertType}/>)}
  </div>;
}
